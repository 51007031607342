header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

div > h5:first-child{
    font-size:1.2rem
}
h1 {
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.amination {
  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
/* ==================== CTA ====================  */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ==================== HEADER SOCIALS ====================  */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 5rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ==================== ME ====================  */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 25rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ==================== SCROLL DOWN ====================  */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 7rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
/* ================== MEDIA QUERIES (MEDIUM DEVICES)================== */
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}

/* ================== MEDIA QUERIES (SMALL DEVICES)================== */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
